.launch-call-for-funds-button {
  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }
  margin-bottom: 60px;
  @extend .button-action;
}

.button-action {
  color: #fff;
  font-size: 15px !important;
  background-color: #f16e00;
  border: 1px solid #f16e00;
  outline: none;

  &:hover {
    color: #fff;
    background-color: #fe7100;
  }
}

.call-for-funds-indicators-row {
  margin-top: 10px;
  margin-bottom: 20px;
}

.card, .card-header {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}

.card, .card-body {
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
}

.card {
  border: 0;

  .row:not(:last-child) {
    margin-bottom: 20px;
  }
}

.indicators, .assignments {

  .card-body {
    background-color: #f9f9fc;
    border-top-right-radius: 15px !important;
    border-top-left-radius: 15px !important;
  }

  .with-delays {
    color: #eb004e;
  }

  .card-header {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 20px;
    background: inherit;
    border-bottom: 0;
  }

  .CircularProgressbar .CircularProgressbar-path {
    stroke: #333;
  }

  .CircularProgressbar .CircularProgressbar-text {
    fill: #333;
  }

}

.assignments {
  .card-body {
    background-color: #fff;
  }

  .table {
    margin-bottom: 0;
  }
}

/* .custom-card-content {
  padding: 1rem;
  text-align: center;
  @include media-breakpoint-down(md) {
    margin-top: 10px;
  }

  .progress-bar {
    background-color: #eb004e
  }

}*/
