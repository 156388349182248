.condominium-property-management {

  .condominium-property-add-button {
    margin-bottom: 20px;
    font-size: 15px;
    color: #fff;
    background-color: #f16e00;
    border: 1px solid #f16e00;
    outline: none;

    &:hover {
      color: #fff;
      background-color: #fe7100;
    }
  }

  .condominium-properties-table {
    font-size: 15px;
    background-color: #fff;

    thead.first-level-head th {
      font-size: 15px;
      text-align: center;
      border-top: 0;
      border-bottom: 0;
    }

    tbody.first-level-body {
      font-family: 'Open Sans', sans-serif, serif;
      font-size: 14px;
      td {
        vertical-align: middle;
      }
    }

    .condominium-property-name, .condominium-property-new-exercise-action {
      color: #f16e00;
    }

    .condominium-property-new-exercise-action {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

  }

}
