@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/breakpoints";
@import "node_modules/bootstrap/scss/mixins/transition";
@import "src/adapters/primary/uicomponents/layout/progresscomponents/spinners/spinners";
@import "src/adapters/primary/uicomponents/layout/topbar/topbar.component";
@import "src/adapters/primary/uicomponents/layout/sidebar/sidebarMenu.component";

body {
  font-family: 'Open Sans', sans-serif !important;
  font-weight: 300;
}

.main {
  padding-top: 70px;
  min-height: calc(100vh);
  background-color: rgb(242, 243, 246);
  @include media-breakpoint-up(md) {
    width: calc(100%);
  }

  .content {
    @include media-breakpoint-up(md) {
      padding-left: 260px;
    }
    margin-bottom: 40px;
  }
}

form {
  .error-message {
    color: red !important;
  }
}

.date-picker {
  width: 100%
}

.list-table {
  font-size: 15px;
  background-color: #fff;

  thead.first-level-head th {
    font-size: 15px;
    text-align: center;
    border-top: 0;
    border-bottom: 0;
  }

  tbody.first-level-body {
    font-family: 'Open Sans', sans-serif, serif;
    font-size: 14px;
  }

}

.link {
  cursor: pointer;
  color: #f16e00;
  &:hover {
    text-decoration: underline;
  }
}