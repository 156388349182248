.sidebar-menu {
  .selected-menu {
    color: #f16e00 !important;
  }
}

.bm-item {
  outline: 0;
}

/* Position and sizing of burger button */
.bm-burger-button {
  @include media-breakpoint-down(md) {
    position: fixed;
    width: 26px;
    height: 20px;
    left: 10px;
    top: 16px;
    z-index: 9999;
  }
  @include media-breakpoint-up(md) {
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  @include media-breakpoint-down(md) {
    background: #f16e00;
  }
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  @include media-breakpoint-down(md) {
    background: #a90000;
  }
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  @include media-breakpoint-down(md) {
    height: 24px;
    width: 24px;
  }
}

/* Color/shape of close button cross */
.bm-cross {
  @include media-breakpoint-down(md) {
    background: #bdc3c7;
  }
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  @include media-breakpoint-down(md) {
    position: fixed;
    height: 100%;
    display: block;
  }
}

/* General sidebar styles */
.bm-menu {
  background-color: rgb(61, 62, 65);
  color: #fff;
  padding: 1.5em 0.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  padding: 0.8em;

  a {
    color: #bdbdbd;

    &:hover {
      color: #f16e00;
    }
  }
}

/* Individual item */
.bm-item:first-child {
  margin-bottom: 1em;
}

.bm-item {
  display: inline-block;

  .nav-link {
    color: #fff;

    &:hover {
      color: #f16e00;
    }
  }

  &.logo-wrapper {
    // height: 57px;
    font-size: 1.3rem;
    padding-top: 10px;
  }

  &.logo-wrapper:after {
    content: "";
    position: absolute;
    top: 110px;
    right: 15px;
    height: 1px;
    width: calc(100% - 30px);
    background-color: hsla(0, 0%, 100%, .5);
  }
}

/* Styling of overlay */
.bm-overlay {
  @include media-breakpoint-down(md) {
    background: rgba(0, 0, 0, 0.3) !important;
  }
  @include media-breakpoint-up(md) {
    background: transparent !important;
  }
}

.sidebar-sticky {
  display: none;
  font-family: 'Open Sans', sans-serif;
  @include media-breakpoint-up(md) {
    display: block;
    position: fixed;
    color: #fff;
    background-color: rgb(61, 62, 65);
    top: 0;
    z-index: 1000;
    height: calc(100vh);
    border-right: 1px solid #ececec;
    .nav {
      color: rgb(189, 189, 189);

      a {
        width: 100%;
        height: 100%;
        color: inherit;
        cursor: pointer;
      }

      &:hover {
        color: #f16e00 !important;
      }
    }
  }

  .logo-wrapper {
    // height: 57px;
    font-size: 1.3rem;
    padding-top: 2px;
  }

  .logo-wrapper:after {
    content: "";
    position: absolute;
    top: 60px;
    right: 15px;
    height: 1px;
    width: calc(100% - 30px);
    background-color: hsla(0, 0%, 100%, .5);
  }
}

.authenticated-user-box {
  font-size: 20px;

  p {
    margin-top: 15px;
  }

  a {
    color: #fff;

    &:hover {
      text-decoration: underline;
      color: #fff;
    }
  }
}
