@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/breakpoints";
@import "node_modules/bootstrap/scss/mixins/transition";
@import "src/adapters/primary/uicomponents/screens/callsforfundsscreen/callsForFunds-old";
@import "./listing/callsForFundsListing";
@import "./listing/callsForFundsListingItem";
@import "./listing/expandedDetailsListItem";

/*.custom-card {
  background-color: #fff;
  background-clip: border-box;
  border-radius: .25rem;
  box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, .03),
  0 0.9375rem 1.40625rem rgba(4, 9, 20, .03),
  0 0.25rem 0.53125rem rgba(4, 9, 20, .05),
  0 0.125rem 0.1875rem rgba(4, 9, 20, .03);
  border: 0 solid rgba(26, 54, 126, .125);
  transition: all .2s;
}*/

.launch-call-for-funds-button {
  @include media-breakpoint-down(md) {
    margin-top: 20px;
  }
  color: #fff;
  font-size: 12px;
  background-color: #f16e00;
  border: 1px solid #f16e00;
  outline: none;

  &:hover {
    color: #fff;
    background-color: #fe7100;
  }
}

.call-for-funds-indicators-row {
  margin-top: 10px;
  margin-bottom: 20px;
}

.custom-card-content {
  padding: 1rem;
  text-align: center;
  @include media-breakpoint-down(md) {
    margin-top: 10px;
  }

  .progress-bar {
    background-color: #eb004e
  }

}
