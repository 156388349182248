.co-owner-edition-form {
  .form-group {
    margin-bottom: 20px;
  }

  button.btn-primary.btn {
    background-color: #f16e00;
    border: 1px solid  #f16e00;
    outline: none;
    &:hover {
      background-color: #fe7100;
    }
  }

  .include-conjoint-checkbox{
    text-align: left;
    @media (max-width:992px) {
      margin-bottom: 20px;
    }
  }

  .next-link-wrapper {
    margin-top: 15px;
    a {
      color: #f16e00;
      &:hover {
        color: #fe7100;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

}

.autocomplete-dropdown-container {
  margin-top: 3px;
}

.google-autocomplete-suggestions-item {
  padding: 8px;
}

.google-autocomplete-suggestions-item:not(:first-child) {
  border-top: 1px solid #dedede
}