.call-for-funds-assignment-row {
  td, th {
    vertical-align: middle !important;
  }

  .co-owner-assignment-fullname {
    cursor: pointer;
    color: #f16e00;

    &:hover {
      text-decoration: underline;
    }
  }

  .btn.btn-link {
    cursor: pointer;
    color: #f16e00;
    font-size: 0.9rem;

    &:hover {
      text-decoration: underline;
    }

  }

  .accordion-angle {
    color: #f16e00;
    font-size: 20px;
    cursor: pointer;
  }

  .cashed-money {
    .full {
      color: #89c084;
    }

    .partially {
      color: #c64522;
    }

    .none {
      color: #eb004e;
    }
  }


}
