.calls-for-funds-listing {

  .form-group {
    margin-bottom: 0;
  }

  .form-control {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size: 25px;
    padding-left: 0;
    border: 0;

    select:after {
      font-size: 25px !important
    }
  }

  .assignments-table {
    font-size: 15px;
    background-color: #fff;

    thead.assignments-table-first-level-head th {
      font-size: 15px;
      text-align: center;

      border-top: 0;
      border-bottom: 0;
    }

    tbody.assignments-table-first-level-body {
      font-family: 'Open Sans', sans-serif, serif;
      font-size: 14px;
    }

  }
}