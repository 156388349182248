.navbar {
  background-color: #f3f3f3;
  color: #f16e00;
  z-index: 930 !important;
  font-family: 'Open Sans', sans-serif;

  .navbar-nav {
    @include media-breakpoint-up(md) {
      padding-left: 260px;
    }

    @include media-breakpoint-down(sm) {
      padding-left: 20px;
    }

    .nav-link, .navbar-text {
      color: #f16e00 !important;
    }

    .auth-spin {
      -webkit-animation: fa-spin 0.8s infinite linear;
      animation: fa-spin 0.8s infinite linear;
    }
  }

  border-bottom: 1px solid #e2e2e2;
}

.navbar-brand {
  display: none !important;
  @include media-breakpoint-down(md) {
    display: inline-block !important;
  }
}

.navbar-light .navbar-text {
  color: #fff;

  .fa-sign-out-alt {
    color: #f16e00;
  }
}

.condominium-properties-selection {
  .select-input > div {
    background-color: #f3f3f3;
  }
  width: 400px;
  @include media-breakpoint-down(sm) {
    width: 400px;
  }
  @include media-breakpoint-down(xs) {
    width: 230px;
  }
}