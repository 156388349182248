.special-keys-management {

  .special-keys-add-button {
    margin-bottom: 20px;
    font-size: 15px;
    color: #fff;
    background-color: #f16e00;
    border: 1px solid #f16e00;
    outline: none;

    &:hover {
      color: #fff;
      background-color: #fe7100;
    }
  }
  
}